// MODAL DIALOG
.new-modal-offer-form {
    .error {
        color: $color-alert;
    }

    .success {
        color: $color-success;
    }

    .new-bg-warning {
        color: $color-warning-bg;
    }

    .info-sign-check {
        color: $color-check-sign;
    }

    .info-sign-icon {
        color: $color-info-darker;
    }

    .back-sign-info {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #0288d1;
        padding: 2%;
        border-radius: 4px;

        @media only screen and (max-width: $breakpoint-phone) {
            text-align: left;
            padding: 6%;
        }
    }

    .button-container {
        @media only screen and (max-width: $breakpoint-phone) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 6%;
        }
    }

    .title-icon {
        font-size: 90px !important;
    }

    .action-button {
        width: 30%;

        @media only screen and (max-width: $breakpoint-phone) {
            width: 100%;
        }
    }

    .icon-requirements-modal {
        display: inline-block;
    }

    .icon-requirements-modal {
        margin: 0;
    }

    .dot {
        height: 30px;
        width: 25px;
        background-color: $color-warning;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 58px;
        right: 281px;
    }

    .modal-list-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .modal-list-item {
            margin-left: 25%;
            margin-bottom: 0.5rem;

            @media only screen and (max-width: $breakpoint-phone) {
                margin-left: 0;
                font-size: 14px;
            }

            @media only screen and (max-width: $breakpoint-extra-small) {
                font-size: 12px;
            }
        }

        .modal-list-item-sign {
            @extend .modal-list-item;

            margin-left: 7%;
            font-size: 14px;
            margin-bottom: 0rem;
        }

        .modal-title-list-sign {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 150%;
            /* identical to box height, or 24px */

            letter-spacing: 0.15px;

            color: #013654;
            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
            margin-left: 0%;
        }
    }

    .MuiDialogTitle-root {
        padding: 30px 24px 0 24px !important;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .MuiAvatar-root {
        background: $color-success-bg;
        width: 70px;
        height: 70px;
        margin: auto;
    }

    .MuiSvgIcon-root {
        font-size: 50px;
        margin: inherit;
        z-index: 2;
        position: relative;
    }

    .MuiSvgIcon-root.close-icon {
        color: $color-primary !important;
        font-size: 25px;
    }

    .MuiSvgIcon-root.MuiSvgIcon-root-list {
        font-size: 30px;
    }

    .MuiTypography-h2 {
        text-align: center;

        .new-modal-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 150%;
            color: $color-primary;
            margin-bottom: 0;
            margin-top: 1rem;
        }

        .new-modal-subtitle {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: $color-primary;
        }
    }
}
